<template>
  <div
    v-if="isVisible"
    class="flex flex-wrap p-4 m-4 bg-gray-200 shadow-2xl"
  >
    <div class="w-1/4 mt-3 bg-gray-200">

      <div
        class="w-full text-sm border-b-2"
        v-html="$tk('AvailableBalance.YourInventory')"
      ></div>

      <div class="w-full mt-2 text-right">

        <PButton
          @click="onSave"
          :loading="isSaving"
          class="mt-2 mr-2"
        >
          <PTranslation k="Common.Actions.Register" />
        </PButton>

      </div>
    </div>
    <div class="flex flex-wrap w-3/4">
      <div
        class="flex w-full p-3 sm:w-1/2 lg:w-1/3"
        v-for="(product, index) in products"
        :key="index"
      >
          <div class="max-w-sm p-2 pb-0 w-22">
            <div class="w-20 h-12 bg-center bg-no-repeat bg-contain" :style="`background-image:url('${assetsBaseUrl}${product.productImage}')`"></div>
          </div>

          <div class="w-full pl-4">
            <span class="text-sm whitespace-no-wrap">{{ product.productName }}</span>
            <PNumber
              class="w-32"
              v-model="product.sortQuantity"
            />
          </div>

      </div>

    </div>
  </div>
</template>

<script>
  import http from "@/utilities/http"
  import { mapGetters } from "vuex"
  import {
    filter,
    forEach
  } from "lodash"

  export default {

    name: "AvailableBalance",

    computed: {

      ...mapGetters(["location"]),

      assetsBaseUrl () {
        return this.$appConfig.assetsBaseUrl
      },

      isVisible () {
        return this.location.sortAgreement &&
               this.products.length > 0
      }

    },

    data () {
      return {
        products: [],
        isSaving: false
      }
    },

    methods: {

      async onSave () {

        this.isSaving = true

        try {

          let arr = []

          forEach(this.products, p => {
            arr.push(http.post("LocationProduct", {
              locationId: this.location.id,
              productId: p.productId,
              sortQuantity: p.sortQuantity
            }))
          })

          await Promise.all(arr)

          this.reload()

        } catch (e) {
           this.$notifyError(
             this.$tk("AvailableBalance.ErrorSavingBalance"),
             this.$formatApiError(e)
            )
        }

        this.isSaving = false

      },

      async reload (locationId) {
        try {
          const products = await http.get("LocationProducts", { params: { locationId } })
          this.products = filter(products, r => r.isPooling)
        } catch {
          this.products = []
        }
      }
    },

    async created () {
      await this.reload(this.location.id)
    }

  }
</script>
